import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ModalProvider } from "./hooks/useModal";

import GlobalStyle from "./styles/global";

ReactDOM.render(
  <React.StrictMode>
    <ModalProvider>
      <App />
    </ModalProvider>
    <GlobalStyle/>
  </React.StrictMode>,
  document.getElementById("root")
);

import logo from "../../assets/nautica_logo_1080p.png";
import chat_icon from "../../assets/chat_icon.svg";

import { GiHamburgerMenu } from "react-icons/gi";

import { Container, Content } from "./styles";

interface HeaderProps {
  onOpenMenu: () => void;
  onOpenContacts: () => void;
  contactsIsOpen: boolean;
  onCloseContacts: () => void;
  logoSize?: number;
  logoImg?: string;
}

export function Header({
  contactsIsOpen,
  onCloseContacts,
  onOpenContacts,
  onOpenMenu,
  logoSize,
  logoImg,
}: HeaderProps) {
  function handleOpenMenu() {
    onCloseContacts();
    onOpenMenu();
  }

  return (
    <Container>
      <Content contactsIsOpen={contactsIsOpen} logoSize={logoSize}>
        <header>
          <button onClick={handleOpenMenu}>
            <GiHamburgerMenu color="#ff8a35" />
          </button>
          <div>
            <img src={logoImg || logo} alt="logo" />
          </div>
          <button onClick={onOpenContacts}>
            <img src={chat_icon} alt="contato" />
          </button>
        </header>
      </Content>
    </Container>
  );
}

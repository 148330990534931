import styled, { css, keyframes } from "styled-components";

interface BackgroundProps {
  backgroundImg?: string;
}

const appear = keyframes`
  from{
    opacity: 0;
  }
`;

export const Background = styled.div<BackgroundProps>`
  width: 100vw;
  max-width: 770px;

  height: ${window.innerHeight}px;
  min-height: 600px;
  ${(props) =>
    css`
      background: url(${props.backgroundImg}) no-repeat;
      background-size: cover;
    `}
`;

export const Container = styled.div`
  animation: ${appear} 1s;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  padding: 0.5rem;
  grid-template-rows: 1fr 8fr 1fr;

  background: linear-gradient(rgba(0, 0, 0, 0.25), black);
`;

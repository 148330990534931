import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Home } from "../pages/Home";
import { Recipe } from "../components/Recipe";
import { BreadedShrimp } from "../pages/Recipes/BreadedShrimp";
import { Curiosities } from "../components/Curiosities";
import { Tilapia } from "../pages/Curiosities/Tilapia";

export function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/receitas/camarao-empanado" exact>
          <Recipe>
            <BreadedShrimp />
          </Recipe>
        </Route>
        <Route path="/curiosidades/tilapia" exact>
          <Curiosities>
            <Tilapia/>
          </Curiosities>
        </Route>
      </Switch>
    </Router>
  );
}

import { createGlobalStyle, keyframes } from "styled-components";

const appearMenu = keyframes`
    from{
        opacity: 0;
        transform: translateY(-100px);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
`;

export default createGlobalStyle`

    :root{

        --background-dark: #333333;
        --background-dark-transparency: rgba(0,0,0,0.6);
        --background-light: #e0e0e0;
        --primary: #ff6e32;
        --secondary: #ff8a35;
        --text-light: #fff;
        --text-dark: #333333;
    }

    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }

    html{

        @media (max-width: 769px){
            font-size: 150%;
        }

        @media (max-width: 500px){
            font-size: 100%;
        }

        @media (max-width: 320px){
            font-size: 62.5%;
        }
    }

    body{
        font-family: 'Poppins', sans-serif;
        font-weight: 200;
        color: #fff;
        background: var(--background-dark);     

    }

    .react-modal-overlay-menu{

        display: flex;
        align-self: center;
        justify-self: center;

        width: 100vw;
        max-width: 770px;

        background: rgba(0,0,0,0.6);
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        display: flex;
        justify-content: center;

        animation: ${appearMenu} 0.5s;
    }
    .menu-content{
        width: 100%;
        height: 70%;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: var(--background-dark);

        border-radius: 0 0 2rem 2rem;
    }

    .react-modal-overlay-contacts{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;

        margin-top: 0.5rem;
        margin-right: 0.5rem;

        animation: ${appearMenu} 0.5s;
    }

    .contacts-content{
        
        width: 3rem;
        height: 12rem;

        display: grid;
        grid-template-rows: repeat(4, 1fr);

        button{
            border-radius: 50% 50% 0 0;
            background: rgba(255,255,255,0.3);
            width: 3rem;
            height: 3rem;
            border: none;
        }

        a{
            &:last-child{
                border-radius: 0 0 50% 50%;
            }

            width: 3rem;
            height: 3rem;

            background: rgba(255,255,255,0.3);
        }

    }

   

`;

import { Container, Content, Span } from "./styles";
import { Link } from "react-router-dom";

export function Slide3() {
  return (
    <Container>
      <Content>
        <Span textLenght={"VOCÊ".length} fontSize={3}>
          VOCÊ
        </Span>
        <Span textLenght={"SABIA?".length} fontSize={3}>
          SABIA?
        </Span>
        <text>
          <h1>TILÁPIA</h1>
          <p>
            A Tilápia é altamente valorizada pela sua riqueza de nutrientes, tais como vitaminas e
            minerais, incluindo quantidades significativas de proteínas, ômega 3 os ácidos gordos,
            selênio, fósforo, potássio, vitamina B6, e ácido pantotênico.
          </p>
        </text>

        <Link to="/curiosidades/tilapia">
          <button>Ver mais</button>
        </Link>
      </Content>
    </Container>
  );
}

import React, { createContext, Dispatch, ReactNode, useContext, useState } from "react";

interface ModalContextProps {
  menuIsOpen: boolean;
  contactsIsOpen: boolean;
  setContactsIsOpen: Dispatch<React.SetStateAction<boolean>>;
  handleOpenMenu: () => void;
  handleCloseMenu: () => void;
  handleOpenContacts: () => void;
  handleCloseContacts: () => void;
}

interface ModalProviderProps {
  children: ReactNode;
}

const ModalContext = createContext({} as ModalContextProps);

export function ModalProvider({ children }: ModalProviderProps) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [contactsIsOpen, setContactsIsOpen] = useState(false);

  function handleOpenMenu() {
    setMenuIsOpen(true);
  }
  function handleCloseMenu() {
    setMenuIsOpen(false);
  }

  function handleOpenContacts() {
    setContactsIsOpen(true);
  }
  function handleCloseContacts() {
    setContactsIsOpen(false);
  }

  return (
    <ModalContext.Provider
      value={{
        contactsIsOpen,
        handleCloseContacts,
        handleCloseMenu,
        handleOpenContacts,
        handleOpenMenu,
        menuIsOpen,
        setContactsIsOpen,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export function useModal() {
  const context = useContext(ModalContext);

  return context;
}

import Modal from "react-modal";
import { Container, Content } from "./styles";

import { IoClose } from "react-icons/io5";

import profile_photo from "../../assets/profile.jpg";

import product_icon from "../../assets/products_icon.svg";
import recipes_icon from "../../assets/recipes_icon.svg";
import lamp_icon from "../../assets/lamp_icon.svg";
import cart_icon from "../../assets/cart_icon.svg";

interface MenuProps {
  menuIsOpen: boolean;
  onRequestCloseMenu: () => void;
}

export function Menu({ menuIsOpen, onRequestCloseMenu }: MenuProps) {
  return (
    <Modal
      isOpen={menuIsOpen}
      onRequestClose={onRequestCloseMenu}
      overlayClassName="react-modal-overlay-menu"
      className="menu-content"
    >
      <Container>
        <Content>
          <header>
            <button onClick={onRequestCloseMenu}>
              <IoClose></IoClose>
            </button>
            <button>
              <img src={profile_photo} alt="Foto de perfil" />
            </button>
          </header>
          <div>
            <button>
              <img src={product_icon} alt="Produtos" />
              <span>Produtos</span>
            </button>
            <button>
              <img src={recipes_icon} alt="Produtos" />
              <span>Receitas</span>
            </button>
            <button>
              <img src={lamp_icon} alt="Produtos" />
              <span>Dicas</span>
            </button>
            <button>
              <img src={cart_icon} alt="Produtos" />
              <span>Carrinho</span>
            </button>
            <button></button>
            <button></button>
            <button></button>
            <button></button>
            <button></button>
          </div>
        </Content>
      </Container>
    </Modal>
  );
}

import Modal from "react-modal";

import { FaWhatsapp, FaInstagram, FaTelegram } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

import { Container, Content } from "./styles";

interface ContactsProps {
  contactsIsOpen: boolean;
  onRequestCloseContacts: () => void;
}

export function Contacts({ contactsIsOpen, onRequestCloseContacts }: ContactsProps) {
  return (
    <Modal
      isOpen={contactsIsOpen}
      overlayClassName="react-modal-overlay-contacts"
      className="contacts-content"
    >
      <Container>
        <Content>
          <button onClick={onRequestCloseContacts}>
            <IoClose />
          </button>

          <a href="https://www.whatsapp.com/?lang=pt_br" target="_blank" rel="noreferrer"> 
            <FaWhatsapp />
          </a>
          <a href="https://www.instagram.com/nautica_pescados/" target="_blank" rel="noreferrer">
            <FaInstagram />
          </a>
          <a href="https://web.telegram.org/" target="_blank" rel="noreferrer">
            <FaTelegram />
          </a>
        </Content>
      </Container>
    </Modal>
  );
}

import { Container, Content, Span } from "./styles";
import { Link } from "react-router-dom";

export function Slide2() {
  return (
    <Container>
      <Content>
        <Span textLenght={"CAMARÃO".length} fontSize={3}>
          CAMARÃO
        </Span>
        <Span textLenght={"EMPANADO".length} fontSize={3}>
          EMPANADO
        </Span>

        <text>
          <h1>INGREDIENTES: </h1>
          <p>
            400g de camarão limpo Náutica Pescados 2 ovos 250g de farinha de rosca 250g de farinha
            de trigo.
          </p>
          <h1>PREPARO:</h1>
          <p>
            Pegue os seus favoritos e faça uma mistura com os frutos do mar para deixar ainda mais
            gostoso. Se não souber como, coloque apenas sal, pimenta e um pouco de limão. Reserve.
            Em um prato, coloque a farinha de trigo, em outro os ovos e por fim, a farinha de rosca
            em outro prato. Pegue um camarão por vez e passe em todos esses ingredientes seguindo a
            mesma ordem acima. Depois, frite em óleo bem quente e retire quando começarem a dourar.
          </p>
        </text>

        <Link to="/receitas/camarao-empanado">
          <button>Ver mais</button>
        </Link>
      </Content>
    </Container>
  );
}

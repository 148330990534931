import { ReactNode } from "react";
import { useHistory } from "react-router-dom";

import { useModal } from "../../hooks/useModal";
import { Header } from "../Header";

import { Container, Content } from "./styles";

import background_image from "../../assets/background_image_camarao_empanado.png";
import recipe_icon from "../../assets/recipes_icon.svg";

import { FiArrowLeft, FiShare, FiPlus } from "react-icons/fi";

interface RecipeProps {
  children: ReactNode;
}

export function Recipe({ children }: RecipeProps) {
  const { contactsIsOpen, handleCloseContacts, handleOpenContacts, handleOpenMenu } = useModal();

  const history = useHistory();

  return (
    <Container backgroundImg={background_image}>
      <Content>
        <Header
          contactsIsOpen={contactsIsOpen}
          onCloseContacts={handleCloseContacts}
          onOpenContacts={handleOpenContacts}
          onOpenMenu={handleOpenMenu}
          logoImg={recipe_icon}
        ></Header>
        {children}
        <footer>
          <button
            onClick={() => {
              history.goBack();
            }}
          >
            <FiArrowLeft />
          </button>
          <button>
            <FiPlus />
          </button>
          <button>
            <FiShare />
          </button>
        </footer>
      </Content>
    </Container>
  );
}

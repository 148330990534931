import styled from "styled-components";

export const Container = styled.div``;

export const Content = styled.div`
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 2rem;
    height: 2rem;

    color: #fff;
  }
`;

import { Contacts } from "./components/Contacts";
import { Menu } from "./components/Menu";
import { useModal } from "./hooks/useModal";
import { Routes } from "./services/Routes";

function App() {
  const { handleCloseMenu, menuIsOpen, handleCloseContacts, contactsIsOpen } = useModal();

  return (
    <>
      <Routes />
      <Menu onRequestCloseMenu={handleCloseMenu} menuIsOpen={menuIsOpen} />
      <Contacts onRequestCloseContacts={handleCloseContacts} contactsIsOpen={contactsIsOpen} />
    </>
  );
}

export default App;

import styled, { keyframes } from "styled-components";

const appearFromLeft = keyframes`
  from{
    width: 0%;
  }
`;

const appear = keyframes`
  from{
    opacity: 0;
  }
`;

interface SpanProps {
  textLenght: number;
  fontSize?: number;
}

export const Container = styled.div`
  animation: ${appear} 1s;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Span = styled.span<SpanProps>`
  &:first-child {
    font-weight: 500;
  }
  font-size: ${(props) => props.fontSize || 3}rem;
  border-bottom: 10px solid #fff;

  width: ${(props) => (props.textLenght - 3) * 10}%;

  animation: ${appearFromLeft} 1s;
`;

import { Container, Content, Span } from "./styles";
export function Slide1() {
  return (
    <Container>
      <Content>
          <Span textLenght={"NÁUTICA".length} fontSize={3}>
            NÁUTICA
          </Span>
          <Span textLenght={"PESCADOS".length} fontSize={3}>
            PESCADOS
          </Span>
      </Content>
    </Container>
  );
}

import styled from "styled-components";

export const Container = styled.footer``;
export const Content = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    button {
      width: 1rem;
      height: 1rem;

      background: var(--primary);

      border-radius: 50%;
      border: none;

      /* outline: 0.1rem solid var(--primary);
      outline-offset: 0.5rem; */

      box-shadow: 0 0 2px #fff;

      margin: 0 2.5rem;
    }
  }
`;

import { Link } from "react-router-dom";
import { Container, Content } from "./styles";

interface FooterProps {
  setIndex: (value: number) => void;
}

export function Footer({ setIndex }: FooterProps) {
  return (
    <Container>
      <Content>
        <footer>
          <Link to="/">
            <button
              onClick={() => {
                setIndex(0);
              }}
            ></button>
          </Link>
          <Link to="/">
            <button
              onClick={() => {
                setIndex(1);
              }}
            ></button>
          </Link>
          <Link to="/">
            <button
              onClick={() => {
                setIndex(2);
              }}
            ></button>
          </Link>
        </footer>
      </Content>
    </Container>
  );
}

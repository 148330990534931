import styled, { keyframes } from "styled-components";

const appearFromLeft = keyframes`
  from{
    width: 0%;
  }
`;

const appear = keyframes`
  from{
    opacity: 0;
  }
`;

interface SpanProps {
  textLenght: number;
  fontSize?: number;
}

export const Container = styled.div`
  animation: ${appear} 1s;
`;
export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  text {
    display: flex;
    flex-direction: column;

    max-height: 45vh;

    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;

    h1 {
      font-size: 2.2rem;
      font-weight: 300;
      padding-bottom: 1rem;
      padding-top: 1.5rem;
    }

    p {
      font-size: 1.5rem;
    }

    background: -webkit-linear-gradient(#fff, transparent);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }

  a {
    align-self: center;

    display: flex;
    align-items: center;
    justify-content: baseline;
    text-decoration: none;
  }

  button {
    width: 12rem;
    height: 3rem;

    border-radius: 2rem;

    font-size: 1.3rem;
    border: none;
    outline: none;
  }
`;

export const Span = styled.span<SpanProps>`
  &:first-child {
    font-weight: 500;
  }
  font-size: ${(props) => props.fontSize || 3}rem;
  border-bottom: 10px solid #fff;

  width: ${(props) => (props.textLenght - 3) * 10}%;

  animation: ${appearFromLeft} 1s;
`;

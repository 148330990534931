import { useState } from "react";
import background_image1 from "../../assets/background_homepage.png";
import background_image2 from "../../assets/background_slide2.png";
import background_image3 from "../../assets/background_slide3.png";

import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Slide1 } from "../../components/Slide1";
import { Slide2 } from "../../components/Slide2";
import { Slide3 } from "../../components/Slide3";
import { useModal } from "../../hooks/useModal";

import { Container, Content, Background } from "./styles";

export function Home() {
  const { contactsIsOpen, handleCloseContacts, handleOpenContacts, handleOpenMenu } = useModal();

  const [indexSlides, setIndexSlides] = useState(0);

  const background_images = [background_image1, background_image2, background_image3];

  function setIndex(value: number) {
    setIndexSlides(value);
  }

  return (
    <Container>
      <Background backgroundImg={background_images[indexSlides]}>
        <Content>
          <Header
            contactsIsOpen={contactsIsOpen}
            onCloseContacts={handleCloseContacts}
            onOpenContacts={handleOpenContacts}
            onOpenMenu={handleOpenMenu}
            logoSize={indexSlides === 0 ? 6 : 3}
          ></Header>

          {indexSlides === 0 ? (
            <Slide1></Slide1>
          ) : indexSlides === 1 ? (
            <Slide2></Slide2>
          ) : (
            <Slide3></Slide3>
          )}

          <Footer setIndex={setIndex} />
        </Content>
      </Background>
    </Container>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    button {
      &:last-child {
        border-radius: 50%;
      }
      width: 4rem;
      height: 4rem;

      margin: 1rem 1rem 0 1rem;

      background: transparent;
      border: none;

      svg {
        width: 2.5rem;
        height: 2.5rem;

        color: #ff8a35;
      }

      img {
        width: 100%;
        height: 100%;

        border-radius: 50%;
      }
    }
  }

  div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1rem;

    > button {
      width: 6.5rem;
      height: 6.5rem;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      background: transparent;
      border: 1px solid #fff;

      border-radius: 1rem;

      &:focus {
        border: 1px solid var(--secondary);
      }

      img {
        width: 3.5rem;
        height: 3.5rem;
      }

      span {
        color: var(--text-light);
        margin-top: 0.5rem;
        font-size: 1.2rem;
        font-weight: 200;
      }
    }
  }
`;

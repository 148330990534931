import styled, { css } from "styled-components";

interface ContainerProps {
  backgroundImg?: string;
}

export const Container = styled.div<ContainerProps>`
  width: 100vw;
  ${(props) =>
    css`
      background: url(${props.backgroundImg}) no-repeat;
      background-size: cover;
    `}
`;
export const Content = styled.div`
  display: flex;
  padding: 0.5rem;
  flex-direction: column;

  background: linear-gradient(rgba(0, 0, 0, 0.25), black);

  text {
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 2.2rem;
      font-weight: 300;
      padding-bottom: 1rem;
      padding-top: 1.5rem;
    }

    p {
      font-size: 1.5rem;
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    margin: 2rem 0;

    button {
      width: 3rem;
      height: 3rem;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;

      background: transparent;
      border: 1px solid #fff;

      svg {
        color: #fff;
        width: 2rem;
        height: 2rem;
      }
    }
  }
`;

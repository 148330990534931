import styled, { css } from "styled-components";

interface ContentProps {
  contactsIsOpen: boolean;
  logoSize?: number;
}

export const Container = styled.header``;
export const Content = styled.div<ContentProps>`
  header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    button {
      &:last-child {
        ${(props) =>
          props.contactsIsOpen &&
          css`
            visibility: hidden;
          `}
      }

      background: transparent;
      border: 0;

      svg {
        width: 2.5rem;
        height: 2.5rem;
      }

      img {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    div {
      img {
        max-width: ${(props) => props.logoSize || 6}rem;
      }
    }
  }
`;
